import React,{useState} from 'react';
import Delivery from './views/Delivery'
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import './App.css';
import { AppLayout } from './layout/appLayout';

function App() {

  const [keyPressed, setKeyPressed] = useState(0)

  return (
    <div>
      <ToastContainer
        position="bottom-left" />
      <AppLayout keyPressed={keyPressed} setKeyPressed={setKeyPressed}>
        <Delivery keyPressed={keyPressed} setKeyPressed={setKeyPressed}/>
      </AppLayout>
    </div>
  );
}

export default App;
