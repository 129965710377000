import React, { useEffect } from 'react';
import { Card } from 'reactstrap';

export const AppLayout = ({ children, keyPressed, setKeyPressed }) => {

  const styles = {
    body: {
      height: '100vh',
      padding: '2vw',
      backgroundColor: "white"
    },
    appCard: {
      height: '100%',
      overflow: 'hidden',
      borderRadius: '3vw',
      backgroundColor: "white",
      border: 'none'
    },
    main: {
      height: '100%',
      overflow: 'hidden',
      padding: '3vw'
    }
  };

  const resetAnimation = () => {
    if (keyPressed > 0) {
      var el = document.getElementById('timerAnimation');
      if (el) {
        el.style.animation = 'none';
        setTimeout(function () {
          el.style.animation = `fade-out-bg ${process.env.REACT_APP_INACTIVITY_TIMER}s linear`;
        }, 1);
      }
    }
  };

  const resetInactivityTimer = () => {
    resetAnimation();
  };

  useEffect(() => {
    resetInactivityTimer();
  }, []);

  useEffect(() => {
    if (keyPressed !== 0) {
      resetInactivityTimer()
    }
  }, [keyPressed])

  return (
    <div style={styles.body} >
      {keyPressed === 0
        ?
        <div
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: "#0282C3"
          }}
        ></div>
        :
        <div
          id="timerAnimation"
          style={{
            position: 'absolute',
            top: '-10%',
            left: '0',
            width: '100%',
            height: '110%',
            background: "linear-gradient(0deg, #0282C3 90%, white 100%)",
            animation: `fade-out-bg ${process.env.REACT_APP_INACTIVITY_TIMER}s linear`
          }}
        ></div>
      }
      <Card style={styles.appCard}>
        <main style={styles.main}>
          {children}
        </main>
      </Card>
    </div>
  );
};